import { useEffect, useMemo, useRef } from 'react'
import { debounce } from '~/src/helpers/debounce'
import { Constants } from '~/src/helpers/constants'

const useDebounce = <A = unknown, R = void>(fn: (args: A) => R, ms?: number): ((args: A) => Promise<R>) => {
  const [ debouncedFun, teardown ] = debounce<A, R>(fn, ms)

  useEffect(() => () => teardown(), [])

  return debouncedFun
}

const useDebounceCallable = (callback: () => void, ms: number = Constants.DEBOUNCE_TIME * 2) => {
  const ref = useRef<any>()

  useEffect(() => {
    ref.current = callback
  }, [ callback ])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, ms)
  }, [])

  return debouncedCallback[0]
}

export {
  useDebounce,
  useDebounceCallable
}
